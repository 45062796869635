import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Link from '../../../Components/Link.js'
import BackToHomeButton from "../../../Components/BackToHomeButton";

const WrongIpWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const WrongIpTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 121.7%;

  color: rgba(255, 255, 255, 0.87);

  margin-bottom: 8px;
`
const WrongIpDescription = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  margin-bottom: 32px;
`
const WrongIpStyled = styled.span`
  color: #ee2b17;
`

function WrongIp(props) {

  const { t } = useTranslation('authentication');
  const ct = useTranslation('common').t;

  return (
    <WrongIpWrapper>
      <WrongIpTitle>{t('wrongIp.title')}</WrongIpTitle>
      <WrongIpDescription>
        {t('wrongIp.description.partOne')}
        <WrongIpStyled>{props.ip}</WrongIpStyled>
        {t('wrongIp.description.partTwo')}
        <br />
        <br />
        {t('wrongIp.description.contact')}
        <Link href="mailto:support@facct.ru">
          {' ' + ct('email')}
        </Link>
      </WrongIpDescription>
      <BackToHomeButton type={'blue'} >{t('back')}</BackToHomeButton>
    </WrongIpWrapper>
  )
}

export default WrongIp
