import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@ui/core/Button';
import { Text } from '@ui/core/Text';
import { Badge } from '@ui/core/Badge';
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './styles.css'

import Logo from '../../Components/Logo.js'
import LangSwitcher from '../../Components/LangSwitcher.js'
import Link from '../../Components/Link.js'
import Card from '../../Components/Card/Card.js'
import { ProductCard } from '../../Components/ProductCard';
import { TwoFaCard } from '../../Components/TwoFaCard';
import { useProducts } from '../../hooks/useProducts';

import AppContext from '../../Components/Context/AppContext'

const StyledWelcome = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;

  color: rgba(255, 255, 255, 0.87);

  margin-bottom: 16px;
`;
const StyledHello = styled.div`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledDescription = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
  line-height: 24px;

  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;
`;

const StyledContent = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFooter = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 44px;
  width: 350px;
  max-width: 350px;
  
  color: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;

  > * {
    margin-top: 8px;
  }

  flex: 0 0 auto;
`;


function Authorized(props) {

  const appContext = useContext(AppContext);

  const { t } = useTranslation('authorized');
  const ct = useTranslation('common').t;
  const { availableProducts, otherProducts } = useProducts();
  const [twoFaCardVisible, setTwoFaCardVisible] = useState(!appContext.sessionParams.twoFaIsOn);
  // если количество доступных продуктов - 1,
  // ширина карточки и отступ увеличиваются
  function getCardClassList(products) {
    return classNames('AuthorizedPage--Content__RightSide__Card')
  }
  return (
    <div className="AuthorizedPage">
      <div className="AuthorizedPage--Header">
        <Logo lang={props.lang} className="AuthorizedPage--Logo" />
        <div className="AuthorizedPage--Header__control-buttons">
          <LangSwitcher checked={props.lang} onChange={props.onChangeLang} />

          <Link href="/logout" style={{ marginLeft: '24px' }}>
            <Button
              view="outline"
              className="AuthorizedPage--Header--ControlButtons__logout-button"
            >
              {t('logout')}
            </Button>
          </Link>

        </div>
      </div>
      <div className="AuthorizedPage--Content">
        <div className="AuthorizedPage--Content__LeftSide">

          <StyledContent>

            <StyledWelcome>
              <StyledHello className="AuthorizedPage--Content__LeftSide--hello" >{t('hello')}</StyledHello>
              {appContext.sessionParams.username}
            </StyledWelcome>
            <StyledDescription>{t('choseProduct')}</StyledDescription>
            {
              twoFaCardVisible && <TwoFaCard setTwoFaCardVisible={setTwoFaCardVisible} />
            }
            {/*<StyledDescription className="AuthorizedPage--Content__LeftSide__topicsTitle">*/}
            {/*  {t('topics.title')}*/}
            {/*</StyledDescription>*/}
            {/*<Link className="AuthorizedPage--Content__LeftSide__topic">*/}
            {/*  {t('topics.changePassword')}*/}
            {/*</Link>*/}
            {/*<Link className="AuthorizedPage--Content__LeftSide__topic">*/}
            {/*  {t('topics.settings')}*/}
            {/*</Link>*/}
            {/*<Link className="AuthorizedPage--Content__LeftSide__topic">*/}
            {/*  {t('topics.rights')}*/}
            {/*</Link>*/}

          </StyledContent>
          <StyledFooter>
            <div>
              {t('footer.support')} {ct('phone')} ·
              <Link href={"mailto:" + ct('email')}>
                {' ' + ct('email')}
              </Link>
            </div>
            <div>
              {`© 2003 – ${new Date(Date.now()).getFullYear()} `}
              <Link href={ct('gibLink')} >{t('footer.name')}</Link>
              {t('footer.description')}
            </div>
          </StyledFooter>

        </div>
        <div className="AuthorizedPage--Content__RightSide">
          <div className="AuthorizedPage--Content__Products">
            <div className="AuthorizedPage--Content__ActiveProducts">
              <div className="AuthorizedPage--Content__RightSide__TitleBlock">
                <Text as="h4" color="high" type="h4" className="AuthorizedPage--Content__RightSide__Title">{t('active')}</Text>
                <Badge type="counter" view="default">{availableProducts.length}</Badge>
              </div>
              <div className="AuthorizedPage--Content__RightSide__CardsWrapper">
                {availableProducts.map((product) => {
                  return (
                    <Card
                      key={product.name}
                      className={getCardClassList(availableProducts)}
                      {...product}
                    />
                  )
                })}
              </div>
            </div>
            {
              otherProducts.length > 0 &&
              <>
                <div className="AuthorizedPage--Content__AllProducts">
                  <Text as="h4" color="high" type="h4" className="AuthorizedPage--Content__RightSide__AllProductsTitle">{t('allProducts')}</Text>
                </div>
                <div className="AuthorizedPage--Content__RightSide__CardsWrapper">
                  {
                    otherProducts.map(product => (
                      <ProductCard
                        key={product.name}
                        {...product}
                      />
                    ))
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Authorized
