import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Table, TableRow, TableBody, TableHead, TableHeadCell, TableBodyCell } from '@ui/core/Table';
import { api } from '../../Components/Api/index';
import { useTranslation } from 'react-i18next';
import AppContext from "../../Components/Context/AppContext";
import './styles.css';
import SearchForm from './SearchForm';
import Date from '../../Components/Value/Date';
import SubscriptionEditorModal from './SubscriptionEditorModal';
import {useHistory, useRouteMatch} from "react-router-dom";
import queryString from "query-string";
import { IconImageEdit } from '@ui/core/Icon/16/Icon_imageEdit';
import { IconContentAdd } from '@ui/core/Icon/16/Icon_contentAdd';
import CompanyEditorModal, { activeForRegionsOptions } from "./CompanyEditorModal";
import SamlModal from "./SamlModal";

function getStaticCompanyList() {

    return {"rows":[]};

}

async function fetchCompanyList(page, searchValue) {

    return await api.get('/api/web/helm/company', {
        params: {
            page: page,
            q: searchValue,
            limit: 30
        }
    })

}

async function saveSubscription(subscription) {
    const companyId = subscription.company.id;
    const projectId = subscription.subscription.projectId;

    return await api.post(`/api/web/helm/company/${companyId}/subscription?projectId=${projectId}`, subscription.subscription)
}

async function saveCompany(company) {
    return await api.post(`/api/web/helm/company/${company.id}`, company)
}

async function getSamlSettings(id) {
    return await api.get(`/api/web/helm/company/${id}/saml_settings`)
}

async function saveSamlSettings(id, settings) {
    return await api.post(`/api/web/helm/company/${id}/saml_settings`, settings)
}

async function initSubscriptionForAllUsers(company, subscription) {
    return await api.post(`/api/web/helm/company/${company.id}/subscription/init_for_users?projectId=${subscription.projectId}`, subscription.subscription)
}

function CompanyList(props) {

    const appContext = useContext(AppContext);

    const history = useHistory();
    const route = useRouteMatch();
    const query = queryString.parse(document.location.search);

    const t = useTranslation('helm').t;

    const [searchValue, setSearchValue] = useState(query.q);
    const [page, setPage] = useState(1);

    const [companiesCount, setCompaniesCount] = useState(0);
    const [companies, setCompanies] = useState([]);

    const [subscriptionEditorModalIsOpen, setSubscriptionEditorModalIsOpen] = useState(false);
    const [editingSubscription, setEditingSubscription] = useState({
        company: null,
        subscription: null,
    });

    const [companyEditorModalIsOpen, setCompanyEditorModalIsOpen] = useState(false);
    const [samlEditorModalIsOpen, setSamlEditorModalIsOpen] = useState(false);
    const [editingCompany, setEditingCompany] = useState({
        company: null,
        samlSettings: null
    });

    const fetch = useCallback((searchValue) => {
        // setCompanies(getStaticCompanyList().rows);
        fetchCompanyList(page, searchValue).then((data) => {
            setCompanies(data.rows);
            setCompaniesCount(data.count || 0);
        });
    }, [page, searchValue]);

    useEffect(() => {
        fetch(searchValue);

        const query = queryString.stringify({
            q: searchValue
        });

        history.push(route.path + '?' + query);
    }, [fetch, history, page, route.path, searchValue]);

    const handleSubmit = (searchValue) => {
        setSearchValue(searchValue);
    }

    const saveSubscriptionHandle = (subscription) => {

        saveSubscription(subscription).then((data) => {

            if (data.error) {
                alert(data.error);
            } else {
                alert('Subscription is saved');
                fetch(searchValue);
            }

            setSubscriptionEditorModalIsOpen(false);
            setEditingSubscription({
                company: null,
                subscription: null,
            });

        });

    }

    const saveCompanyHandle = (company) => {

        saveCompany(company).then((data) => {

            if (data.error) {
                alert(data.error);
            } else {
                alert('Company is saved');
                fetch(searchValue);
            }

            setCompanyEditorModalIsOpen(false);
            setEditingCompany({
                company: null,
            });

        });

    }

    const saveSamlHandle = (samlSettings) => {

        saveSamlSettings(editingCompany.company.id, samlSettings).then((data) => {

            if (data.error) {
                alert(data.error);
            } else {
                alert('SAML settings is saved');
                fetch(searchValue);
            }

            setSamlEditorModalIsOpen(false);
            setEditingCompany({
                company: null,
                samlSettings: null,
            });

        });

    }

    const subscriptionEditorModalOpen = (company, subscription) => {
        setSubscriptionEditorModalIsOpen(true);
        setEditingSubscription({
            company, subscription
        });
    }

    const companyEditorModalOpen = (company) => {
        setCompanyEditorModalIsOpen(true);
        setEditingCompany({
            company: company,
        });
    }

    const samlModalOpen = async (company) => {

        let samlSettings = null

        if (company.hasSamlSettings) {
            samlSettings = await getSamlSettings(company.id)
        }

        setSamlEditorModalIsOpen(true);
        setEditingCompany({
            company,
            samlSettings
        });
    }

    const initSubscriptionForAllUsersHandler = (company, subscription) => {

        const confirmation = window.confirm('Are you really want to init access for all the company\'s users?');

        if (!confirmation) {
            return;
        }

        initSubscriptionForAllUsers(company, subscription).then(() => {
            alert('Done');
        });

    }

    const getRegions = function (regionsAsString) {
        let regionsNames = []
        let regionsList = regionsAsString.split(',');
        let optionsMap = {}

        activeForRegionsOptions.forEach((v) => {
            optionsMap[v.value] = v.content;
        });

        regionsList.forEach((v) => {
            regionsNames.push(optionsMap[v]);
        });

        regionsNames.sort();

        return regionsNames.join(', ')
    }

    return (
        <div>

            <SubscriptionEditorModal
                key={editingSubscription.subscription?.id}
                isOpen={subscriptionEditorModalIsOpen}
                subscription={editingSubscription}
                onRequestClose={() => {
                    setSubscriptionEditorModalIsOpen(false);
                }}
                onSubmit={saveSubscriptionHandle}
            />

            <CompanyEditorModal
                key={editingCompany.company?.id}
                isOpen={companyEditorModalIsOpen}
                company={editingCompany}
                onRequestClose={() => {
                    setCompanyEditorModalIsOpen(false);
                }}
                onSubmit={saveCompanyHandle}
            />

            <SamlModal
                key={"saml-modal-" + editingCompany.company?.id}
                isOpen={samlEditorModalIsOpen}
                company={editingCompany}
                onRequestClose={() => {
                    setSamlEditorModalIsOpen(false);
                }}
                onSubmit={saveSamlHandle}
                editMode={!editingCompany?.company?.hasSamlSettings}
            />

            <SearchForm q={searchValue} onSubmit={handleSubmit} />
            <Table view="card" className="company-table" >
                <TableHead>
                    <TableRow>
                        <TableHeadCell>{t('ID')}</TableHeadCell>
                        <TableHeadCell>{t('CRM ID')}</TableHeadCell>
                        <TableHeadCell style={{width: '300px'}} >{t('Name')}</TableHeadCell>
                        <TableHeadCell style={{width: '50px', whiteSpace: 'nowrap'}} title="Strict mode" >{t('SM')}</TableHeadCell>
                        <TableHeadCell>{t('Info')}</TableHeadCell>
                        <TableHeadCell>{t('Subscriptions')}</TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company, index) => (
                        <TableRow key={`company-${index}`}>
                            <TableBodyCell style={{whiteSpace: 'nowrap'}} >{company.id}</TableBodyCell>
                            <TableBodyCell style={{whiteSpace: 'nowrap'}} >{company.crmId}</TableBodyCell>
                            <TableBodyCell>{company.name}</TableBodyCell>
                            <TableBodyCell>{company.strictMode ? '*' : ''}</TableBodyCell>

                            <TableBodyCell>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Manager')}:</span> {company.accountManager}</div>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Country')}:</span> {company.country}</div>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Created')}:</span> <Date value={company.createdAt} /></div>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Updated')}:</span> <Date value={company.updatedAt} /></div>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('Regions')}:</span>{ getRegions(company.activeForRegions) }</div>
                                <div  style={{display: 'flex'}}  ><span className="field-name" >{t('SAML')}:</span>

                                    <span style={{color: '#8ccafe', textDecoration: 'underline'}} onClick={() => samlModalOpen(company)} >{ company.hasSamlSettings ? 'show' : 'create' }</span>

                                    {
                                        company.hasSamlSettings && <span style={{marginLeft: "10px"}} ><a style={{color: '#8ccafe', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer" href={document.location.origin + '/saml/' + company.publicId + '/metadata'} >metadata</a> </span>
                                    }

                                </div>
                            </TableBodyCell>

                            <TableBodyCell>

                                {
                                    company.subscriptions.length > 0 &&
                                    <div style={{border: "1px solid #ffffff61", borderRadius: "10px"}} >
                                        <Table className="internal-table" >

                                            <TableHead>
                                                <TableRow>
                                                    <TableHeadCell style={{width:'50px'}} >{t('ID')}</TableHeadCell>
                                                    <TableHeadCell style={{width:'200px'}} >{t('Project')}</TableHeadCell>
                                                    <TableHeadCell style={{textAlign: 'center', width:'50px'}} >{t('Is trial')}</TableHeadCell>
                                                    <TableHeadCell style={{width:'200px'}} >{t('Period')}</TableHeadCell>
                                                    <TableHeadCell style={{width: '300px'}} >{t('Info')}</TableHeadCell>
                                                    <TableHeadCell style={{textAlign: 'center', width:'50px'}} />
                                                    <TableHeadCell style={{textAlign: 'center', width:'50px'}} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {
                                                    company.subscriptions.map((subscription, index) => (
                                                        <TableRow key={`subscription-${index}`}>
                                                            <TableBodyCell style={{whiteSpace: 'nowrap'}} >{subscription.id}</TableBodyCell>
                                                            <TableBodyCell>[{subscription.projectId}] {subscription.projectName}</TableBodyCell>
                                                            <TableBodyCell style={{textAlign: 'center'}} >{subscription.isTrial ? 'yes' : 'no'}</TableBodyCell>
                                                            <TableBodyCell style={{whiteSpace: 'nowrap'}} >

                                                                {subscription.dateStart} - {subscription.dateEnd}

                                                            </TableBodyCell>
                                                            <TableBodyCell style={{whiteSpace: 'nowrap'}} >
                                                                <div style={{display: 'flex', whiteSpace: 'nowrap'}} ><span className="field-name" >{t('Manager')}:</span> {subscription.accountManager || '-/-'}</div>
                                                                <div style={{display: 'flex', whiteSpace: 'nowrap'}} ><span className="field-name" >{t('Created')}:</span> <Date value={subscription.createdAt} /></div>
                                                                <div style={{display: 'flex', whiteSpace: 'nowrap'}} ><span className="field-name" >{t('Updated')}:</span> <Date value={subscription.updatedAt} /></div>
                                                            </TableBodyCell>

                                                            <TableBodyCell>

                                                                {
                                                                    subscription.projectId === 15

                                                                        ?
                                                                        <IconImageEdit onClick={() => {subscriptionEditorModalOpen(company, subscription)}} />
                                                                        :
                                                                        ''
                                                                }

                                                            </TableBodyCell>

                                                            <TableBodyCell>

                                                                {
                                                                    subscription.projectId === 15

                                                                        ?
                                                                        <div title={t('Init subscription for all users of the company')} >
                                                                            <IconContentAdd onClick={() => initSubscriptionForAllUsersHandler(company, subscription)} />
                                                                        </div>

                                                                        :
                                                                        ''
                                                                }

                                                            </TableBodyCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>

                                        </Table>
                                    </div>
                                }

                                {
                                    !company.subscriptions.map(subscription => subscription.projectId).includes(15) &&
                                    <IconImageEdit onClick={() => {subscriptionEditorModalOpen(company, {})}} />
                                }

                            </TableBodyCell>

                            <TableBodyCell>

                                <IconImageEdit onClick={() => {
                                    company.activeForRegionsList = company.activeForRegions.split(',');
                                    companyEditorModalOpen(company)}
                                } />

                            </TableBodyCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );

}

export default CompanyList;
