import React from 'react'
function Logo({ lang, className }) {
  return (

    <span className={className}>

      <svg width="160" height="20" viewBox="0 0 160 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0.489258H24.88V4.84481H4.8608V9.19882H23.5904V13.5528H4.8608V19.1528H0V0.489258Z" fill="white" fillOpacity="0.9"/>
          <path d="M26.1009 15.4194H29.9681V19.1528H26.1009V15.4194Z" fill="white" fillOpacity="0.9"/>
          <path d="M67.0608 15.4194H63.192V19.1528H67.0608V15.4194Z" fill="white" fillOpacity="0.9"/>
          <path d="M97.0321 15.4226H100.899V19.1544H97.0321V15.4226Z" fill="white" fillOpacity="0.9"/>
          <path d="M149.931 0.492346V0.489258H145.072V0.492346H135.003V4.8479H145.072V19.1528H149.931V4.8479H160V0.492346H149.931Z" fill="white" fillOpacity="0.9"/>
          <path d="M156.133 15.4226H160V19.1544H156.133V15.4226Z" fill="white" fillOpacity="0.9"/>
          <path d="M69.3983 7.64713V12.0011C69.3983 15.9522 72.7167 19.1559 76.8127 19.1559H94.2448V14.8019H74.2607V4.84636H94.2448V0.49235H76.8127C72.7183 0.49235 69.3983 3.6961 69.3983 7.64713Z" fill="white" fillOpacity="0.9"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M52.9712 0.489258C57.0656 0.489258 60.384 3.69146 60.384 7.64404V9.19882H60.3856V13.5528H60.384V19.1513H55.5232V13.5528H38.4304V19.1528H33.5728V13.5544H33.5712V9.20037H33.5728V7.64404C33.5728 3.69146 36.8928 0.489258 40.9872 0.489258H52.9712ZM38.4304 4.84327V9.19882H55.5232V4.84327H38.4304Z" fill="white" fillOpacity="0.9"/>
          <path d="M134.722 15.4226H130.854V19.1544H134.722V15.4226Z" fill="white" fillOpacity="0.9"/>
          <path d="M103.222 12.0011V7.64713C103.222 3.6961 106.541 0.49235 110.635 0.49235H128.069V4.84636H108.085V14.8019H128.069V19.1559H110.637C106.541 19.1559 103.222 15.9522 103.222 12.0011Z" fill="white" fillOpacity="0.9"/>
      </svg>

    </span>

  )
}

export default Logo
