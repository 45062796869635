import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@ui/core/Card';
import { Text } from '@ui/core/Text';
import { Button } from '@ui/core/Button';
import { CompanySelect } from '../CompanySelect';
import { MessageModal } from '../MessageModal';
import { api } from '../Api/index';

import AppContext from '../../Components/Context/AppContext'
import IndicatorTag from '../IndicatorTag';
import './ProductCard.css';

export const getMonthList = (t) => {
    return [t('January'),
        t('February'),
        t('March'),
        t('April'),
        t('May'),
        t('June'),
        t('July'),
        t('August'),
        t('September'),
        t('October'),
        t('November'),
        t('December')];
}

const getFormatDays = (date,t) => {
    const tDate = new Date(date);
    const tDays = tDate.getDate();
    const tMonth = tDate.getMonth();
    const tYear = tDate.getFullYear();
    const tMonthName = getMonthList(t)[tMonth];
    const targetDate = tDate.getTime();
    const curDate = new Date().getTime();
    const daysDiff = Math.round((curDate - targetDate) / 86400000);
    return daysDiff <= 31 ? `${daysDiff} ${t('days')}` : `${tDays} ${tMonthName} ${tYear}`;
}

export const ProductCard = ({ type, name, code, trialLink, description, subscription, ajax, successMessage }) => {
    const at = useTranslation('authentication').t;
    const appContext = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isTryDemoError, setIsTryDemoError] = useState(false);
    const trialLinkPrepared = trialLink + '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone

    let trialStartedTitle = at(successMessage + '.title');
    let trialStartedText = at(successMessage + '.text');

    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleCloseMessageModel = () => {
        setIsMessageModalOpen(false);
        setIsTryDemoError(false);
    }

    const ajaxRequestToInitTrial = async (trialLink) => {

        let response = await api.get(trialLink);

        // let response = {
        //     success: false
        // };

        if (response.success && response.success) {
            setIsMessageModalOpen(true);
        } else {
            setIsMessageModalOpen(true);
            setIsTryDemoError(true);
        }

        setIsModalOpen(false);
    }

    const getOnClickHandler = () => {

        // Для Trebuchet выводим так, потому что для try demo не нужны компании и там внешняя ссылка
        if (code === 'tr') {
            return () => window.location = trialLink;
        }

        if (!trialLink || !appContext.sessionParams.companies || appContext.sessionParams.companies.length === 0) {
            return null;
        }

        if (appContext.sessionParams && appContext.sessionParams.companies && appContext.sessionParams.companies.length > 1) {
            return handleOpenModal;
        }

        if (ajax) {
            return () => ajaxRequestToInitTrial(trialLinkPrepared);
        }

        return () => window.location = trialLinkPrepared;
    }

    const onClickHandler = getOnClickHandler();

    return (
        <Card className={`ProductCard ProductCard_${code} ${onClickHandler && 'ProductCard_with_link'}`} onClick={onClickHandler} >
            <div className="ProductCard-Header">
                <Text as="h5" color="high" type="h5" className="ProductCard-Title">{name}</Text>
                {subscription && subscription.isExpired &&
                    <IndicatorTag indicatorColor="#FA665B">{`${at('expired')} ${getFormatDays(subscription.dateEnd, at)}`}</IndicatorTag>
                }
                {
                    !subscription && !subscription?.isExpired &&
                    <Text className="ProductCard_Description" color="medium" type="body3">
                        {description}
                    </Text>
                }

            </div>
            <div className="ProductCard-Footer">
                {
                    onClickHandler &&
                    <Button as="a" view="filled-brand" className="ProductCard-TrialButton" >
                        {code === 'tr' ? at('runTest') : at('tryDemo')}
                    </Button>
                }
            </div>
            {trialLink &&
                <CompanySelect
                    trialLink={trialLinkPrepared}
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    ajax={ajax}
                    ajaxRequestToInitTrial={ajaxRequestToInitTrial}
                />
            }
            {trialLink &&
                <MessageModal
                    title={isTryDemoError ? at('tryDemoError.title') : trialStartedTitle}
                    text={isTryDemoError ? at('tryDemoError.text') : trialStartedText}
                    isOpen={isMessageModalOpen}
                    onRequestClose={handleCloseMessageModel}
                />
            }
        </Card>
    )
}
