/**
 * Button - стилизованный button c аналогичными атрибутами
 *
 * Пропсы:
 *  type - цвет кнопки. Может иметь два значения: primary(синий) и standard(серый)
 *  textStyle - регистр текста внутри кнопки. Значения: upper/standard
 */

import React from 'react'
import {Button as GibButton} from '@ui/core/Button';


function Button(props) {
  const { className, children, type } = props;

  return (
    <GibButton type={type || 'button'} view="filled-brand" className={className} {...props}>
      {children}
    </GibButton>
  )
}

export default Button
