import React, { useState, useRef, createRef, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { Text } from '@ui/core/Text';
import { TextField } from '@ui/core/TextField';

import { useGenerateTwoFa } from '../../hooks/useGenerateTwoFa';
import './TwoFaQrCode.css';

export const cnTwoFaQrCode = cn('TwoFaQrCode');

export const TwoFaQrCode = ({ onChange, error, token }) => {
    const { data, error: generateError, generate } = useGenerateTwoFa(token);
    const [inputValues, setInputValues] = useState(Array.from({ length: 6 }).map(() => ''));
    const inputRefs = useRef(inputValues.map(() => createRef()));
    const [qrGen, setQrGen] = useState(data || {
        secret: null,
        qr: null
    })

    const handleChangeInput = (index) => (event) => {
        const value = event.target.value;
        if (value === '' || (/^[0-9]+$/.test(value) && value.length <= 1)) {
            const _newValues = [
                ...inputValues.slice(0, index),
                value,
                ...inputValues.slice(index + 1)
            ];
            setInputValues(_newValues);
            if (onChange) {
                onChange(_newValues.join(''));
            }
            if (value !== '') {
                if (inputRefs.current[index + 1]) {
                    inputRefs.current[index + 1].current.focus();
                }
            }
        }
    }
    const handleKeyDown = (index) => (event) => {
        if (event.keyCode === 8 && inputValues[index] === '' && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].current.focus();
        }
    }
    useEffect(() => {
        generate();
    }, []);
    useEffect(() => {
        if (data) {
            setQrGen(data);
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            setInputValues(Array.from({ length: 6 }).map(() => ''));
            inputRefs.current[0].current.focus();
        }
    }, [error]);
    return (
        <div className={cnTwoFaQrCode()}>
            <div className={cnTwoFaQrCode('ImageBlock')}>
                {
                    qrGen.qr && <img src={qrGen.qr} alt="qr code" />
                }
                {
                    qrGen.secret && <Text type="body3" color="medium">{qrGen.secret.replace(/(.{4})/g, '$1 ')}</Text>
                }
            </div>
            <div className={cnTwoFaQrCode('VerField')}>
                <div className={cnTwoFaQrCode('InputsBlock')}>
                    {
                        inputValues.map((value, index) =>
                            <TextField
                                autoFocus={index === 0}
                                controlRef={inputRefs.current[index]}
                                error={!!error}
                                view="dense"
                                key={index}
                                value={value}
                                onKeyDown={handleKeyDown(index)}
                                onChange={handleChangeInput(index)}
                            />
                        )
                    }
                </div>
                {
                    error && <Text type="caption2" color="danger" style={{ display: 'block' }}>{error.message}</Text>
                }
            </div>
            <Text type="body3" color="medium">
                Scan the QR code with two-step verification apps <Text type="body3" color="green">(Google Authenticator or Duo Authentication)</Text> and enter the code you received<Text></Text></Text>
        </div>
    )
}
