import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Table, TableRow, TableBody, TableHead, TableHeadCell, TableBodyCell } from '@ui/core/Table';
import { api } from '../../Components/Api/index';
import { useTranslation } from 'react-i18next';
import AppContext from "../../Components/Context/AppContext";
import SearchForm from './SearchForm';
import './styles.css';
import {Link as RouteLink, useHistory, useRouteMatch} from 'react-router-dom';
import Date from '../../Components/Value/Date';
import queryString from 'query-string';


function getStaticEventList() {

    return {
        "rows": [
            {
                "event": "sso_authentication",
                "userId": "fewfewfew",
                "data": "{\"result\":\"fail\",\"error\":\"Invalid username or password\"}",
                "id": 1,
                "createdAt": "2018-04-26T22:18:00+03:00"
            },
            {
                "event": "sso_authentication",
                "userId": "cd23xd23d23@mail.ru",
                "data": "{\"result\":\"success\"}",
                "id": 2,
                "createdAt": "2018-04-26T22:18:10+03:00"
            },
            {
                "event": "sso_authentication",
                "userId": "cd23xd23d23@mail.ru",
                "data": "{\"result\":\"fail\",\"error\":\"Invalid username or password\"}",
                "id": 3,
                "createdAt": "2018-04-27T15:34:47+03:00"
            },
            {
                "event": "sso_authentication",
                "userId": "cd23xd23d23@mail.ru",
                "data": "{\"result\":\"success\"}",
                "id": 4,
                "createdAt": "2018-04-27T15:34:53+03:00"
            }
        ]
    };

}

async function fetchEventList(page, searchValue) {

    return await api.get('/api/web/helm/event', {
        params: {
            page: page,
            q: searchValue,
            limit: 100
        }
    })

}

function EventList(props) {

    const appContext = useContext(AppContext);


    const history = useHistory();
    const route = useRouteMatch();
    const query = queryString.parse(document.location.search);

    const t = useTranslation('helm').t;

    const [searchValue, setSearchValue] = useState(query.q);
    const [page, setPage] = useState(1);

    const [eventsCount, setEventsCount] = useState(0);
    const [events, setEvents] = useState([]);
    const [companies, setCompanies] = useState({});
    const [processing, setProcessing] = useState(false);

    const fetch = useCallback((searchValue) => {
        // setEvents(getStaticEventList().rows);
        fetchEventList(page, searchValue).then((data) => {
            setEvents(data.rows);
            setEventsCount(data.count || 0);
        });
    }, [page, searchValue]);

    const refetch = () => {
        fetch(searchValue);
    }

    useEffect(() => {
        fetch(searchValue);

        const query = queryString.stringify({
            q: searchValue
        });

        history.push(route.path + '?' + query);
    }, [page, searchValue]);

    const handleSubmit = (searchValue) => {
        setSearchValue(searchValue);
    }

    return (
        <div>
            <SearchForm q={searchValue} onSubmit={handleSubmit} />
            <Table view="card" className="company-table" >
                <TableHead>
                    <TableRow>
                        <TableHeadCell style={{width: '200px', whiteSpace: 'nowrap'}} >{t('Created at')}</TableHeadCell>
                        <TableHeadCell style={{width: '400px', whiteSpace: 'nowrap'}} >{t('User')}</TableHeadCell>
                        <TableHeadCell style={{width: '200px'}} >{t('Event')}</TableHeadCell>
                        <TableHeadCell>{t('Data')}</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.map((event, index) => (
                        <EventRow key={event.id} event={event} refetch={refetch} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );

}

function EventRow(props) {

    let event = props.event;

    const t = useTranslation('helm').t;

    return (
        <TableRow>
            <TableBodyCell><Date value={event.createdAt} /></TableBodyCell>
            <TableBodyCell>{event.userId}</TableBodyCell>
            <TableBodyCell>{event.event}</TableBodyCell>
            <TableBodyCell>{event.data}</TableBodyCell>
        </TableRow>
    );

}

export default EventList;
