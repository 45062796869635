/**
 * Компонент Textfield - стилизованный input c аналогичными атрибутами
 */

import React from 'react'
import { TextField } from '@ui/core/TextField';

function Textfield(props) {
    const { className, id, ...textfieldProps } = props;
    return <TextField {...textfieldProps} id={id || "filled-basic"} label={props.label} view="filled" />

}

export default Textfield
