import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@ui/core/Text';
import { Button } from '@ui/core/Button';

import { TwoFaModalIcon } from '../../../../../Components/TwoFaModal/Icon/TwoFaModal-Icon';
import { cnTwoFaResetPassword } from '../TwoFaResetPassword';
import './TwoFaResetPassword-SuccessfulActivation.css';

export const TwoFaResetPasswordSuccessfulActivation = () => {
    const { t } = useTranslation('authentication');
    const ta = useTranslation('twoFa').t;
    return (
        <div className={cnTwoFaResetPassword('SuccessfulActivation')}>
            <TwoFaModalIcon />
            <Text as="h3" type="h3" color="high" weight="bold" className={cnTwoFaResetPassword('Title')}>{ta('activatedMsg')}</Text>
            <Button as="a" href="/" view="filled-brand" width="max" className={cnTwoFaResetPassword('BackBtn')}>{t('passwordChanged.backToSignIn')}</Button>
        </div>
    )
}