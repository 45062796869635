/**
 * LangSitcher - компонент переключения языка между русским и английским
 *
 * Пропсы:
 *  checked: string - выбранный язык
 *
 * Евенты:
 *  onChange: string - событие смены языка
 */

import React, { useCallback } from 'react'
import { Tabs } from '@ui/core/Tabs';

import './LangSwitcher.css';

const LangSwitcher = ({ className, checked, onChange, ...props }) => {
  const handleChange = useCallback((tabId) => {
    onChange(tabId);
  }, [onChange])

  return (
    <Tabs
      activeTab={checked}
      width="auto"
      tabs={[{
        id: 'ru',
        content: 'Rus'
      }, {
        id: 'en',
        content: 'Eng'
      }]}
      onChange={handleChange}
      view="chips"
    />
  )
}

export default LangSwitcher
