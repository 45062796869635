import React, { useState, useCallback } from 'react';
import { compose } from '@bem-react/core';
import {
    Tabs as TabsBase,
    withSizeS,
    withWidthAuto,
    withViewChips
} from '@ui/core/Tabs/Tabs.base';
import {BrowserRouter as Router, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import CompanyList from './Company';
import UserList from './User';
import EventList from './Event';

const Tabs = compose(withSizeS, withWidthAuto, withViewChips)(TabsBase);

function Helm(props) {

    const route = useRouteMatch();
    const [activeTab, setActiveTab] = useState(route.params.section);
    const history = useHistory();

    const onChangeTabHandle = useCallback((id) => {
        setActiveTab(id);
        history.push('/helm/' + id);
    }, [setActiveTab])

    return (
        <div style={{padding: '20px'}} >

            <Router key={activeTab} >

                <Tabs
                    style={{marginBottom: '20px'}}
                    view="chips"
                    tabs={[
                        { id: 'company', content: 'Companies' },
                        { id: 'user', content: 'Users' },
                        { id: 'event', content: 'Events' }
                    ]}
                    size="s"
                    width="auto"
                    activeTab={activeTab}
                    onChange={onChangeTabHandle}
                />

                <Switch>
                    <Route exact path='/helm/company'><CompanyList /></Route>
                    <Route exact path='/helm/user'><UserList /></Route>
                    <Route exact path='/helm/event'><EventList /></Route>

                </Switch>

            </Router>

        </div>
    );

}

export default Helm;
