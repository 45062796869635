import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {Text} from "@ui/core/Text";
import {Select} from "@ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {Button} from "@ui/core/Button";
import moment from 'moment';
import queryString from "query-string";

Modal.setAppElement('#root');

const TextRender = (className, option) => (
    <Text className={className} type="body1">
        {option.content}
    </Text>
);

const activeForRegionsOptions = [
    {
        value: '1',
        content: 'global'
    },
    {
        value: '2',
        content: 'ru'
    },
];

export default function CompanyEditorModal(props) {

    console.log(props);

    const t = useTranslation('helm').t;

    const getOption = (value, options) => {

        let selectedOption = {};

        options.forEach(option => {
            if (option.value === value) {
                selectedOption = option;
            }
        })

        return selectedOption;
    }

    const getOptions = (values, options) => {

        let selectedOptions = [];

        options.forEach(option => {
            if (values.includes(option.value)) {
                selectedOptions.push(option);
            }
        })

        console.log(selectedOptions);

        return selectedOptions;
    }

    const strictModeOptions = [
        {
            value: true,
            content: 'on'
        },
        {
            value: false,
            content: 'off'
        },
    ];

    const [strictMode, setStrictMode] = useState(props.company?.company?.strictMode)
    const [activeForRegionsList, setActiveForRegionsList] = useState(props.company?.company?.activeForRegions.split(',') || [])

    const submitHandle = () => {
        let company = props.company.company;
        company.strictMode = strictMode;
        company.activeForRegions = activeForRegionsList.join(',');
        props.onSubmit(company);
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className="CompanySelect-Modal"
            overlayClassName="CompanySelect-Overlay"
        >
            <Text as="h4" color="high" type="h4" className="CompanySelect-Title">{t('Company')}</Text>

            <div>
                <Select
                    view="filled"
                    label={t('strictMode')}
                    className="CompanySelect-Select"
                    value={getOption(strictMode, strictModeOptions)}
                    options={strictModeOptions}
                    onChange={e => {
                        setStrictMode(e.target.value.value)
                    }}
                    valueRender={TextRender}
                    width="max"
                    popupWidth="max"
                />
            </div>

            <div>
                <Select
                    view="filled"
                    label={t('activeForRegions')}
                    className="CompanySelect-Select"
                    value={getOptions(activeForRegionsList, activeForRegionsOptions)}
                    options={activeForRegionsOptions}
                    onChange={e => {
                        let activeForRegionsList = [];

                        for (let i in e.target.value) {
                            activeForRegionsList.push(e.target.value[i].value);
                        }

                        setActiveForRegionsList(activeForRegionsList)
                    }}
                    valueRender={TextRender}
                    width="max"
                    popupWidth="max"
                />
            </div>

            <div className="CompanySelect-Controls">
                <Button view="outline" style={{ marginRight: '4px' }} onClick={props.onRequestClose}>{t('cancel')}</Button>

                <Button view="filled-primary" onClick={submitHandle} >
                    {t('save')}
                </Button>

            </div>
        </Modal>
    );

}

export {
    activeForRegionsOptions
}