import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@bem-react/classname';
import Modal from 'react-modal';
import { Text } from '@ui/core/Text';
import { Button } from '@ui/core/Button';

import { useSaveTwoFa } from '../../hooks/useSaveTwoFa';
import { TwoFaQrCode } from '../TwoFaQrCode';
import { TwoFaModalIcon } from './Icon/TwoFaModal-Icon';
import './TwoFaModal.css';

export const cnTwoFaModal = cn('TwoFaModal');

export const TwoFaModal = ({ isOpen, onClose, setTwoFaCardVisible }) => {
    const [qrValue, setQrValue] = useState('');
    const { save, success, error, reset } = useSaveTwoFa();

    const t = useTranslation('twoFa').t;
    const handleChangeQr = (value) => {
        setQrValue(value);

        if (value.length === 6) {
            save(value);
        }
    }

    const handleCloseModal = () => {
        setQrValue('');
        if (success) {
            setTwoFaCardVisible(false);
        }
        reset();
        onClose();
    }

    const handleSaveCode = async (event) => {
        event.preventDefault();
        save(qrValue);
    }

    useEffect(() => {
        if (error) {
            setQrValue('');
        }
    }, [error]);

    return (
        <Modal
            isOpen={isOpen}
            className={cnTwoFaModal()}
            overlayClassName={cnTwoFaModal('Overlay')}
            onRequestClose={handleCloseModal}
        >
            <form onSubmit={handleSaveCode}>
                <Text as="h4" type="h4" color="high" className={cnTwoFaModal('Title')}>{t('modalTitle')}</Text>
                {
                    !success ? <TwoFaQrCode onChange={handleChangeQr} error={error} /> :
                        <div className={cnTwoFaModal('ActivatedContent')}>
                            <TwoFaModalIcon />
                            <Text type="h4" color="high" className={cnTwoFaModal('ActivatedText')}>{t('activatedMsg')}</Text>
                        </div>
                }
                <div className={cnTwoFaModal('Footer')}>
                    {
                        !success ? <>
                            <Button view="outline" onClick={handleCloseModal}>{t('cancel')}</Button>
                            {/*<Button view="filled-primary" disabled={qrValue.length < 6} type="submit">{t('sendCode')}</Button>*/}
                        </> : <Button view="filled-primary" onClick={handleCloseModal}>Close</Button>
                    }

                </div>
            </form>

        </Modal>
    )
}
