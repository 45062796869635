import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {Text} from "@ui/core/Text";
import {Select} from "@ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {Button} from "@ui/core/Button";
import moment from 'moment';
import queryString from "query-string";
import Textfield from "../../Components/Textfield";
import { api } from '../../Components/Api/index';
import ErrorDescription from "../../Components/Error";
import { TextArea } from '@ui/core/TextArea';

Modal.setAppElement('#root');

const TextRender = (className, option) => (
    <Text className={className} type="body1">
        {option.content}
    </Text>
);

async function parseMetaDataUrl(url) {
    return await api.post(`/api/web/helm/utils/parse_metadata_url`, {url})
}

async function parseMetaData(metadata) {
    return await api.post(`/api/web/helm/utils/parse_metadata`, {metadata})
}

function checkUrl(url) {

    if (!url) {
        return false
    }

    return url.search(/https?:\/\/([^/]+\.)+\w{2,}/) !== -1;
}

export default function SamlModal(props) {

    const t = useTranslation('helm').t;

    const [editMode, setEditMode] = useState(props.editMode)
    const [processing, setProcessing] = useState(false)

    const defaultUrl = document.location.origin;

    const [idpMetaDataUrl, setIdpMetaDataUrl] = useState(props.company?.samlSettings?.idpMetaDataUrl)
    const [idpMetaDataUrlError, setIdpMetaDataUrlError] = useState('')

    const [idpMetaData, setIdpMetaData] = useState(props.company?.samlSettings?.idpMetaData)

    const [identityId, setIdentityId] = useState(props.company?.samlSettings?.identityId || defaultUrl)
    const [identityIdError, setIdentityIdError] = useState('')

    const [ssoUrl, setSsoUrl] = useState(props.company?.samlSettings?.ssoUrl || defaultUrl)
    const [ssoUrlError, setSsoUrlError] = useState('')

    const [logoutUrl, setLogoutUrl] = useState(props.company?.samlSettings?.logoutUrl || defaultUrl)
    const [emailAttributeName, setEmailAttributeName] = useState(props.company?.samlSettings?.emailAttributeName || 'email')

    const [cert, setCert] = useState(props.company?.samlSettings?.cert || '-')
    const [certError, setCertError] = useState('')

    const metaDataUrl = props.company?.samlSettings?.metaDataUrl || (document.location.origin + '/saml/' + props.company?.company?.publicId + '/metadata')
    const acsUrl = document.location.origin + '/saml/' + props.company?.company?.publicId + '/acs'
    const loginUrl = document.location.origin + '/saml/' + props.company?.company?.publicId + '/login'
    const ourLogoutUrl = document.location.origin + '/saml/' + props.company?.company?.publicId + '/logout'

    const message = "Our SAML settings:\n" +
                            "1. Metadata URL / Entity ID: " + metaDataUrl + "\n" +
                            "2. Auth URL / Sign on URL: " + loginUrl + "\n" +
                            "3. ACS (Assertion Consumer Service) URL / Reply URL: " + acsUrl + "\n" +
                            "4. Logout URL (optional): " + ourLogoutUrl + "\n" +
                            "5. You should send the user's email in the attribute field \"email\"\n\n" +

                            "From your side we need SAML metadata URL or file.\n\n";

    const parseIdpMetaDataUrl = async () => {
        if ((idpMetaData && idpMetaData.length !== 0) || !checkUrl(idpMetaDataUrl)) {
            return;
        }

        setProcessing(true);

        let parsedMetaData = await parseMetaDataUrl(idpMetaDataUrl);

        if (parsedMetaData.error && parsedMetaData.error) {
            alert(parsedMetaData.error);
        } else {
            setIdpMetaData(parsedMetaData.metaData || '')
            setIdentityId(parsedMetaData.identityId || '')
            setSsoUrl(parsedMetaData.ssoUrl || '')
            setLogoutUrl(parsedMetaData.logoutUrl || '')
            setCert(parsedMetaData.cert || '')
        }

        setProcessing(false);
    }

    const parseIdpMetaData = async () => {

        if (!idpMetaData) {
            return;
        }

        setProcessing(true);

        let buffer = Buffer.from(idpMetaData);
        let parsedMetaData = await parseMetaData(buffer.toString('base64'));

        if (parsedMetaData.error && parsedMetaData.error) {
            alert(parsedMetaData.error);
        } else {
            setIdpMetaData(parsedMetaData.metaData || '')
            setIdentityId(parsedMetaData.identityId || '')
            setSsoUrl(parsedMetaData.ssoUrl || '')
            setLogoutUrl(parsedMetaData.logoutUrl || '')
            setCert(parsedMetaData.cert || '')
        }

        setProcessing(false);
    }

    const submitHandle = () => {

        let error = false

        if (!identityId) {
            setIdentityIdError('required field')
            error = true;
        }

        if (!ssoUrl) {
            setSsoUrlError('required field')
            error = true;
        }

        if (!cert) {
            setCertError('required field')
            error = true;
        }

        if (!error) {
            let companyId = props.company?.company?.id;

            let samlSettings = {
                companyId,
                identityId,
                ssoUrl,
                logoutUrl,
                emailAttributeName,
                cert,
                idpMetaDataUrl,
                idpMetaData
            }

            props.onSubmit(samlSettings);
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className="Modal-Big"
            overlayClassName="CompanySelect-Overlay"
        >
            <Text as="h4" color="high" type="h4" className="CompanySelect-Title">{t('SAML settings')}</Text>

            <div style={{display: "flex"}} >
                <div style={{flex: "1 0 0px", marginRight: "20px"}} >
                    <Text as="h5" color="high" type="h5" className="CompanySelect-Title">{t('GIB SAML settings')}</Text>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Metadata URL')}
                            value={metaDataUrl}
                            readOnly={true}
                        />
                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('ACS URL')}
                            value={acsUrl}
                            readOnly={true}
                        />
                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Login URL')}
                            value={loginUrl}
                            readOnly={true}
                        />
                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Email attribute name')}
                            value={emailAttributeName}
                            readOnly={!editMode}
                            onChange={e => {setEmailAttributeName(e.target.value)}}
                        />
                    </div>

                    <br/>

                    <div>
                        <TextArea
                            width="max"
                            size="m"
                            // height={300}
                            maxHeight={500}
                            autoResize={true}
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            label={t('Message')}
                            value={message}
                            readOnly={true}
                        />
                    </div>

                    <br/>


                </div>

                <div style={{flex: "1 0 0px"}} >
                    <Text as="h5" color="high" type="h5" className="CompanySelect-Title">{t('Idp SAML settings')}</Text>

                    <div>
                        <Textfield
                            // error={idpMetaDataUrlError}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Metadata URL')}
                            value={idpMetaDataUrl}
                            readOnly={!editMode || processing}
                            onChange={e => {

                                if (!checkUrl(e.target.value)) {
                                    setIdpMetaDataUrlError('invalid url');
                                } else {
                                    setIdpMetaDataUrlError(null)
                                }

                                setIdpMetaDataUrl(e.target.value)

                            }}
                            onBlur={parseIdpMetaDataUrl}
                        />

                        {
                            idpMetaDataUrlError && <ErrorDescription style={{marginBottom: '20px'}} code={idpMetaDataUrlError}/>
                        }

                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Metadata')}
                            value={idpMetaData}
                            readOnly={!editMode || processing}
                            onChange={e => {setIdpMetaData(e.target.value)}}
                            onBlur={parseIdpMetaData}
                        />
                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Identity ID')}
                            value={identityId}
                            readOnly={!editMode || processing}
                            onChange={e => {setIdentityId(e.target.value)}}
                        />

                        {
                            identityIdError && <ErrorDescription style={{marginBottom: '20px'}} code={identityIdError}/>
                        }

                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('SSO URL')}
                            value={ssoUrl}
                            readOnly={!editMode || processing}
                            onChange={e => {setSsoUrl(e.target.value)}}
                        />

                        {
                            ssoUrlError && <ErrorDescription style={{marginBottom: '20px'}} code={ssoUrlError}/>
                        }

                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('Logout URL')}
                            value={logoutUrl}
                            readOnly={!editMode || processing}
                            onChange={e => {setLogoutUrl(e.target.value)}}
                        />
                    </div>

                    <br/>

                    <div>
                        <Textfield
                            // error={formAlert}
                            // className="PasswordRecovery__textfield"
                            type="text"
                            label={t('x509 cert')}
                            value={cert}
                            readOnly={!editMode || processing}
                            onChange={e => {setCert(e.target.value)}}
                        />

                        {
                            certError && <ErrorDescription style={{marginBottom: '20px'}} code={certError}/>
                        }

                    </div>

                    <br/>
                </div>
            </div>

            <div className="CompanySelect-Controls">
                <Button view="outline" style={{ marginRight: '4px' }} onClick={props.onRequestClose}>{t('cancel')}</Button>

                {
                    editMode

                    ?
                        <Button view="filled-primary" onClick={submitHandle} >
                            {t('save')}
                        </Button>
                    :
                        <Button view="filled-primary" onClick={() => {setEditMode(true)}} >
                            {t('edit')}
                        </Button>
                }

            </div>
        </Modal>
    );

}
